:root {
  --header-bg-color: #FFFFFF;
  --header-hover-bg-color: #31444f;
  --header-breakbar-color: #00694F;
  --main-color: #00B443;
  --inner-text-color: #646360;
}

.App {
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.main-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  flex-direction: column;
  border-bottom: 37px var(--header-breakbar-color) solid;
}

.header-body {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 17px;
}

.body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.body-header {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 15px 0 15px;
  color: var(--main-color);
  font-family: Arial, Helvetica, sans-serif ;
  margin-top: 2.5rem;
  font-size: 20px;
}
.body-header h1{
  font-size: 22pt;
  margin-bottom: 0.5rem;
}
.body-header p{
  color: var(--inner-text-color);
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 900px;
  padding: 40px;
  background-color: var(--main-color);
}
.form-section > * {
  margin-bottom: 20px;
}

.form-field {
  display: flex;
  width: 100%;
}
.form-field > * {
  padding: 14px;
  border: 0px;
  width: 100%;
  border: 1px solid #D0CCC7;
}
.form-field > *:focus {
  outline: none;
  border: var(--main-color) solid 1px;
}
.form-select-field {
  color: var(--inner-text-color);
}

.button-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-submit{
  background-color: var(--header-breakbar-color);
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  width: 225px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border-top: 1px black solid;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.footer-body > * {
  margin: 0;
}

.footer-legal-links {
  text-decoration: underline;
}
.footer-legal-links:visited {
  text-decoration: underline;
  color: black;
}

.footer-logo {
  margin: 30px 0 20px 0;
}

.footer-legal {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.text-header-large{
  margin-left: 16px;
  text-align: center;
  font-size: 20pt;
  font-family: Arial, Helvetica, sans-serif;
}

.text-header{
  text-align: left;
  text-decoration: none;
  margin: 0px;  
}

.text-field{
  text-align: left;
  font-size: 14px;
  font-family: Frutiger-Roman;
  line-height: 20px;
}
.text-field#small{
  font-size: 11px;
}
.text-field#footer{
  text-decoration: none;
  margin: 0px 0.5rem 0px 0.5rem;
}
.text-field#footer:hover{
  text-decoration: underline;
  color: var(--anchor-hover-color);
  cursor: pointer;
}
.text-field#footer:visited{
  text-decoration: none;
  color: var(--anchor-hover-color);
}

.message-container-email {
  margin-top: 5rem;
}

.field-container-email {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-field-email {
  margin-left: 1rem;
}

.submit-button-container-email{
  margin-top: 1rem;
}

.text-field#button-email{
  text-align: center;
  width: 6rem;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  color: var(--main-color);
  height: 2.5rem;
  width: 2.5rem;
}

@keyframes spin{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (min-width: 900px) {
  .body-container {
    max-width: 900px;
  }

  .form-section {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-field {
    max-width: 335px;
    margin-right: 30px;
  }
  .form-field#email {
    max-width: 470px;
  }

  .footer-container {
    max-width: 900px;
  }
}
